import APIService from "./api";

/** This class will include all services defined in unit-service */
export default class UnitService extends APIService {
  service = "unit-service";
  async getAllUnits(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllUnits" },
      data,
      options
    );
  }
  async createUnit(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createUnit" },
      data,
      options
    );
  }

  async getOneUnit(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getOneUnit" },
      data,
      options
    );
  }
  async deleteUnit(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteUnit" },
      data,
      options
    );
  }
  async updateUnit(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateUnit" },
      data,
      options
    );
  }
  async unitAllocationHistory(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "unitAllocationHistory" },
      data,
      options
    );
  }
  async unitReallocation(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "unitReallocation" },
      data,
      options
    );
  }
  async checkDeviceAllocated(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "checkDeviceAllocated" },
      data,
      options
    );
  }
  async deviceReallocation(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deviceReallocation" },
      data,
      options
    );
  }
  async getAllIcons(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "unitIconUpload" },
      data,
      options
    );
  }
  async getAlerts(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAlerts" },
      data,
      options
    );
  }
  async getAlert(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAlert" },
      data,
      options
    );
  }
  async getEvents(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getEvents" },
      data,
      options
    );
  }
  async createAlert(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createAlert" },
      data,
      options
    );
  }
  async updateAlert(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateAlert" },
      data,
      options
    );
  }
  async removeAlert(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeAlert" },
      data,
      options
    );
  }
  async getOperators(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getOperators" },
      data,
      options
    );
  }
  async updateOperator(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateOperator" },
      data,
      options
    );
  }
  async createOperator(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createOperator" },
      data,
      options
    );
  }
  async getOperator(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getOperator" },
      data,
      options
    );
  }
  async unitLookup(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "unitLookup" },
      data,
      options
    );
  }
  async removeOperator(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeOperator" },
      data,
      options
    );
  }

  async getAllocatedUnitsByOperators(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllocatedUnitsByOperators" },
      data,
      options
    );
  }
  async allocateUnitToOperator(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "allocateUnitToOperator" },
      data,
      options
    );
  }
  async updateUnitAllocation(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateUnitAllocation" },
      data,
      options
    );
  }
  async removeUnitAllocation(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeUnitAllocation" },
      data,
      options
    );
  }
  async getGroupUnits(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getGroupUnits" },
      data,
      options
    );
  }
  async getAlertUsedEntities(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAlertUsedEntities" },
      data,
      options
    );
  }
  async getUnitEvents(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitEvents" },
      data,
      options
    );
  }
  async getAllActivities(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllActivities" },
      data,
      options
    );
  }
  async getTripCoordinates(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTripCoordinatesV2" },
      data,
      options
    );
  }

  async getAllTrips(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllTrips" },
      data,
      options
    );
  }
  async getUnitListV2(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitListV2" },
      data,
      options
    );
  }
  async getUnitDataV2(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitDataV2" },
      data,
      options
    );
  }
  async getTripDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTripDetails" },
      data,
      options
    );
  }
  async getRecordedVideos(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRecordedVideos" },
      data,
      options
    );
  }
  async getUnitListV2Lite(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitListV2Lite" },
      data,
      options
    );
  }
  async saveRepeaters(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "saveRepeaters" },
      data,
      options
    );
  }
  async getRepeaters(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRepeaters" },
      data,
      options
    );
  }
  async getRepeatersUnits(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRepeatersUnits" },
      data,
      options
    );
  }
  async getRepeatersUnitGroups(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRepeatersUnitGroups" },
      data,
      options
    );
  }
  async testRepeater(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "testRepeater" },
      data,
      options
    );
  }
  async getUnitHistory(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitHistoryV2" },
      data,
      options
    );
  }
  async getUnitMessageDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitMessageDetails" },
      data,
      options
    );
  }
  async getUnitWiseSensors(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitWiseSensors" },
      data,
      options
    );
  }
}
