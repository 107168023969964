import APIService from "./api";

/** This class will include all services defined in rule-service */
export default class UnitService extends APIService {
  service = "rule-service";
  async getDeviceRules(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getDeviceRules" },
      data,
      options
    );
  }
  async getDeviceRuleDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getDeviceRuleDetails" },
      data,
      options
    );
  }
  async getListOfApplicableRules(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getListOfApplicableRules" },
      data,
      options
    );
  }
  async getUnitDevices(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitDevices" },
      data,
      options
    );
  }
  async toggleUserConfigurations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "toggleUserConfigurations" },
      data,
      options
    );
  }
  async removeUserConfigurations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeUserConfigurations" },
      data,
      options
    );
  }
  async setUserConfigurations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "setUserConfigurations" },
      data,
      options
    );
  }
  async editUserConfigurations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "editUserConfigurations" },
      data,
      options
    );
  }

  async getTeams(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTeams" },
      data,
      options
    );
  }
  async getPeoples(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getPeoples" },
      data,
      options
    );
  }
  async savePeoplesPrefrences(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "savePeoplesPrefrences" },
      data,
      options
    );
  }
  async saveTeamPrefrences(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "saveTeamPrefrences" },
      data,
      options
    );
  }
  async getTeamPeoples(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTeamPeoples" },
      data,
      options
    );
  }
}
